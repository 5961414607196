import { faShareSquare, faStar, faTrash, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { goTo, handleConfirm, handleDeleteSupplierUser, handleTimedSuccess, sendNewUserEmail, showCustomError } from '../../helpers/general';
import { history } from '../../helpers/history';
import EmptyTableRow from '../controls/EmptyTableRow';
import Translation from '../controls/Translation';
import NewSupplierUser from '../Suppliers/NewSupplierUser';

require('datatables.net-bs4');

const CompanyUsers = props => {
    const { currentContactID, supplierID, PanelName, CardClass, userPermissions } = props;
    const [showModal, setShowModal] = useState(false);
    const [packageService, setPackageService] = useState({ id: 0, price: 0 });
    const [supplierContacts, setSupplierContacts] = useState([]);
    const [userAllowance, setUserAllowance] = useState(null);
    const [packagesEnabled, setPackagesEnabled] = useState(false);
    const toggle = () => setShowModal(!showModal);

    const handleExpandClick = () => {
        toggle();
    }

    useEffect(() => {
        if (supplierID > 0) {
            fetch(`./Suppliers/GetSupplierContacts?supplierID=${supplierID}&currentContactID=${currentContactID}`, {
                method: 'GET',
                headers: authHeader(false, false)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setSupplierContacts(data);
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                });
        }

        return () => {
            setSupplierContacts([]);
        }
    }, [currentContactID, supplierID]);

    useEffect(() => {
        if (supplierID > 0 && userAllowance !== null) {
            fetch(`./Settings/ArePackagesEnabled`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(packagesEnabledData => {
                    setPackagesEnabled(packagesEnabledData)
                    fetch(`./Packages/GetPackageServiceDetails?packageService=pservice_users`, {
                        method: 'GET',
                        headers: authHeader(false, false)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data) {
                                setPackageService({ id: data.uniqueID, price: data.priceExc })
                            }
                        })
                        .catch(err => {
                            console.log("ERROR", err);
                        });
                });
        }

        return () => {
            setPackageService({ id: 0, price: 0 });
        }
    }, [supplierID, userAllowance]);

    useEffect(() => {
        if (supplierID > 0) {
            fetch(`./Suppliers/SupplierHasAvailableAllowance?supplierID=${supplierID}&packageService=pservice_users`, {
                method: 'GET',
                headers: authHeader(false, false)
            })
                .then(allowanceResponse => allowanceResponse.json())
                .then(allowanceData => {
                    setUserAllowance(allowanceData);
                })
        }
    }, [supplierID]);

    const handleResendEmail = (userID, email) => {
        if (userID && userID !== "") {
            handleConfirm(
                `Resend the new account email to '${email}'?`,
                function () {
                    sendNewUserEmail(email)
                        .then(response => {
                            if (response) {
                                showCustomError(`<p>The New User email has been resent to <strong>${email}</strong> to reset the password.</p><p>Please follow instructions to reset the password to log in.</p>`, 'New User Email Sent', 'success', 600);
                            }
                            else {
                                showCustomError('There has been a problem sending the email - if the account has been created, please ask the user to use FORGOTTEN PASSWORD on the homepage', 'Problem with the email address', 'Error')
                            }
                        });
                }
            );
        };
    }

    const handleRemoveFromBasket = selectedContactID => {
        if (selectedContactID) {
            let removeData = {
                "SupplierID": supplierID,
                "ContactID": currentContactID,
                "PackageServiceID": packageService.id,
                "TendererPackageServiceID": selectedContactID
            };

            fetch(`./Payment/RemoveBasketItem`, {
                method: 'POST',
                headers: authHeader(false, false),
                body: JSON.stringify(removeData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        let updatedContacts = [...supplierContacts];
                        var index = updatedContacts.findIndex(x => x.contactID === selectedContactID);
                        updatedContacts[index]["status"] = 1;
                        setSupplierContacts(updatedContacts);
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                })
        }
    }

    const addSearchToBasket = (selectedContactID, contactName) => {
        let searchData = {
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": selectedContactID,
            "Description": contactName,
            "PriceExcVat": packageService.price
        };

        fetch(`./Payment/AddBasketItem?supplierID=${supplierID}&contactID=${currentContactID}`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(searchData)
        })
            .then(response => response.json())
            .then(data => {
                if (data > 0) {
                    let updatedContacts = [...supplierContacts];
                    var index = updatedContacts.findIndex(x => x.contactID === selectedContactID);
                    updatedContacts[index]["status"] = 2;
                    setSupplierContacts(updatedContacts);
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })
    }

    const confirmDeleteUser = (contactID, email) => {
        handleConfirm(`<p>You are about to remove the user with the email address <b>${email}</b> from your Sell2 account.</p>
            <br />
            <p><b>Please Note:</b> Removing this user will remove their access to Sell2, as well as remove access to all of the organisations you have linked to your Sell2 account</p>
            <br />
            <p>Are you sure you wish to continue?</p>`,
            () => {
                handleDeleteSupplierUser(supplierID, contactID, email, currentContactID)
                    .then(() => {
                        let contacts = supplierContacts;
                        let index = contacts.findIndex(x => x.contactID === contactID);

                        if (index >= 0) {
                            contacts.splice(index, 1);
                            setSupplierContacts(contacts);
                            setUserAllowance(userAllowance + 1);
                        }
                    });
            },
            () => { },
            'Remove the User?', '50em', 'YES - Remove the User', 'No - Cancel')
    }

    const IsAdminTooltip = React.forwardRef((_props, ref) => {
        return (
            <>
                <FontAwesomeIcon forwardedRef={ref} icon={faStar} />
                <UncontrolledTooltip placement="right" defaultOpen={false} target={ref}>
                    Administrator Account
                </UncontrolledTooltip>

            </>
        )
    });

    const RemoveUser = React.forwardRef((props, ref) => {
        return (
            <>
                <button ref={ref} size="sm" style={{ color: "red" }} onClick={() => confirmDeleteUser(props.contactID, props.email)}>
                    <FontAwesomeIcon icon={faTrash} />
                </button >
                <UncontrolledTooltip placement="right" defaultOpen={false} target={ref}>
                    Delete Contact
                </UncontrolledTooltip>

            </>
        )
    });

    const linkSearchToAccount = (uniqueID, contactName) => {
        let searchData = {
            "SupplierID": supplierID,
            "ContactID": currentContactID,
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": uniqueID
        };

        fetch(`./Suppliers/LinkContactToAccount`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(searchData)
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    let updatedContacts = [...supplierContacts];
                    var index = updatedContacts.findIndex(x => x.contactID === uniqueID);
                    updatedContacts[index]["status"] = 0;
                    setSupplierContacts(updatedContacts);
                    handleTimedSuccess('success', 'User Account Purchased', `The account for - <b>${contactName}</b> - has been purchased using your accounts available User balance.`);
                    setUserAllowance(userAllowance - 1);
                } else {
                    showCustomError("A problem occurred while retrieving the selected user, please try again.", "Cannot link user", "error")
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })
    }

    const handleSelectedContact = (uniqueID, contactName) => {
        if (userAllowance > 0) {
            handleConfirm(
                `You currently have ${userAllowance} 'User Account' allowance - would you like to use your allowance and continue?`,
                function () {
                    linkSearchToAccount(uniqueID, contactName);
                }
            );

        } else {
            addSearchToBasket(uniqueID, contactName)
        }
    }

    if (supplierID > 0) {
    return (
        <>
            <Card className={CardClass} >
                <CardHeader className="ic-users">
                    <span>
                        <Translation ID={PanelName} />
                    </span>
                    {(packagesEnabled === false || (userAllowance === -1 || userAllowance > 0)) && userPermissions.canCreateUsers &&
                        <span style={{ justifyContent: 'right' }}>
                            <button id='addUser' className="c-supplier-actions__action-button c-supplier-actions__action-button--add-new-user" onClick={(() => handleExpandClick())}>
                                Add New User
                            </button>
                        </span>
                    }
                    {packagesEnabled === true && userAllowance === 0 &&
                        <span style={{ justifyContent: 'right' }}>
                            <button
                                className="c-tender-actions__action-button c-tender-actions__action-button--add-to-basket"
                                id='purchaseUsers'
                                onClick={(() => goTo('/products'))}>
                                Purchase Users
                            </button>
                        </span>
                    }
                </CardHeader>
                <CardBody className='padding-5-1 card-module'>

                    <table id='CompanyUsersTable' className='table'>
                        <thead style={{ position: "sticky", top: "0", background: "var(--card-bg-color)" }}>
                            <tr>
                                <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "5%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} > </span></th>
                                <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "90%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >Name</span></th>
                                <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "5%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} > </span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(supplierContacts).length > 0 &&
                                <>
                                {supplierContacts.map((contact, i) => {
                                        return (
                                            <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                                <td style={{ textAlign: "center" }}>
                                                    {contact.isAdmin === true &&
                                                        <IsAdminTooltip ref={React.createRef()} />
                                                    }
                                                    {contact.isAdmin !== true && userPermissions.canDeleteUsers &&
                                                        <RemoveUser
                                                        ref={React.createRef()}
                                                        contactID={contact.contactID}
                                                        email={contact.email}
                                                    />                                                        
                                                    }
                                                </td>
                                                <td>
                                                    {contact.status === 0 &&
                                                        <a
                                                            href={`/user/${contact.contactID}`}
                                                            onClick={(e) => { e.preventDefault(); history.push(`/user?uid=${contact.contactID}`) }}
                                                            style={{ color: '#00A65A' }}>
                                                            {contact.fullName} {contact.hasVerifiedEmail === false ? <><br /><i style={{fontSize:"0.85rem"}}>[awaiting email verification]</i></> : <></>}
                                                        </a>
                                                    }
                                                    {contact.status !== 0 &&
                                                        <span style={{ color: '#757575', fontStyle: 'italic' }}>
                                                            {contact.fullName}
                                                        </span>
                                                    }
                                                </td>
                                                <td>
                                                    {contact.status === 0 && contact.hasVerifiedEmail === false &&
                                                        <button size="sm" color='link' className='c-text-link' style={{ lineHeight: "unset", padding: ".5rem 0 .5rem 0" }} onClick={() => handleResendEmail(contact.contactID, contact.email)}>
                                                            <span>Resend Email</span><FontAwesomeIcon icon={faShareSquare} />
                                                        </button >
                                                    }
                                                    {contact.status === 1 &&
                                                        <button className="c-tender-actions__action-button c-tender-actions__action-button--add-to-basket" disabled={!userPermissions.canPurchaseProducts} title='Account registered via another Sell2 system. Requires purchasing' onClick={() => handleSelectedContact(contact.contactID, contact.fullName)}>
                                                            Add to basket
                                                        </button>
                                                    }
                                                    {contact.status === 2 && userPermissions.canPurchaseProducts &&
                                                        <button size="sm" color='link' className='c-text-link' style={{ lineHeight: "unset", padding:".5rem 0 .5rem 0" }} onClick={(() => handleRemoveFromBasket(contact.contactID))} >
                                                            Remove from basket
                                                        </button>
                                                    }
                                                    {contact.status === 3 &&
                                                        <button className="c-tender-actions__action-button c-tender-actions__action-button--upgrade" onClick={() => history.push(`/upgrade`)} title="Package upgrade required for additional users">
                                                            Upgrade
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                            }

                            {Object.keys(supplierContacts).length === 0 &&
                                <EmptyTableRow />
                            }
                        </tbody>
                    </table>
                </CardBody>
            </Card>

            <NewSupplierUser
                id='newSupplierUser_Modal'
                supplierID={supplierID}
                isOpen={showModal}
                toggle={toggle}
                cardClass={CardClass}
                panelName={PanelName}
                userAllowance={userAllowance}
                setUserAllowance={setUserAllowance}
            />
        </>
    )

    } else {
        return null;
    }
}

export default CompanyUsers;